import React, { useCallback, useState } from 'react';
import Door from './Door';
import { aiTips } from '../data/aiTips';
import './Calendar.css';

function Calendar({ openDoors, setOpenDoors, setShowSignup, canOpenDoor }) {
  const [timerRunning, setTimerRunning] = useState(false);

  const startTimer = useCallback(() => {
    if (timerRunning) return;
    
    setTimerRunning(true);
    console.log('Timer started...');
    
    setTimeout(() => {
      console.log('Timer completed, showing signup...');
      setShowSignup(true);
      setTimerRunning(false);
    }, 15000);
  }, [timerRunning, setShowSignup]);

  const handleDoorClick = useCallback((doorNumber) => {
    if (!canOpenDoor(doorNumber) && !openDoors[doorNumber]) {
      alert('Diese Tür kann erst am ' + doorNumber + '. Dezember geöffnet werden!');
      return;
    }
    
    if (!openDoors[doorNumber]) {
      setOpenDoors(prev => ({
        ...prev,
        [doorNumber]: true
      }));
    }
    
    startTimer();
  }, [canOpenDoor, openDoors, setOpenDoors, startTimer]);

  return (
    <div className="calendar">
      {[...Array(24)].map((_, index) => {
        const doorNumber = index + 1;
        return (
          <Door
            key={doorNumber}
            number={doorNumber}
            isOpen={openDoors[doorNumber]}
            tip={aiTips[index]}
            onDoorClick={handleDoorClick}
          />
        );
      })}
    </div>
  );
}

export default Calendar; 