import React from 'react';
import './Door.css';

function Door({ number, isOpen, tip, onDoorClick }) {
  return (
    <div 
      className={`door ${isOpen ? 'open' : ''}`}
      onClick={() => onDoorClick(number)}
    >
      <div className="door-front">
        {number}
      </div>
      <div className="door-back">
        <div className="content">
          <h3 className="tip-title">{tip.title} {tip.emoji}</h3>
          <p className="tip-content">{tip.content}</p>
        </div>
      </div>
    </div>
  );
}

export default Door; 