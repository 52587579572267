import React, { useState } from 'react';
import './SignupForm.css';

function SignupForm({ onClose }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    consent: false
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email
        })
      });

      if (response.ok) {
        alert('Anmeldung erfolgreich!');
        onClose();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Fehler beim Senden');
      }
    } catch (error) {
      console.error('Fehler:', error);
      alert('Fehler beim Senden der Anmeldung.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div className="modal-overlay fade-in">
      <div className="modal-content slide-in">
        <button className="modal-close" onClick={onClose}>×</button>
        <div className="modal-body">
          <h2>Newsletter Anmeldung</h2>
          <form onSubmit={handleSubmit}>
            <div className="name-row">
              <input
                type="text"
                name="firstName"
                placeholder="Vorname"
                value={formData.firstName}
                onChange={handleChange}
                required
                disabled={loading}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Nachname"
                value={formData.lastName}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Deine E-Mail"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading}
            />
            <div className="consent-section">
              <label className="consent-label">
                <input
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  required
                  disabled={loading}
                />
                <span>Ich bestätige meine Anmeldung</span>
              </label>
              <p className="consent-text">
                Sie möchten sich nicht anmelden, oder haben Sie diese E-Mail versehentlich erhalten? 
                Dann können Sie diese E-Mail gerne löschen.
              </p>
            </div>
            <button 
              type="submit"
              disabled={loading || !formData.consent}
            >
              {loading ? 'Wird gesendet...' : 'Anmelden'}
            </button>
            <footer className="company-info">
              <p>DiKItal Solutions - MINT Kooperative eG</p>
              <p>Am Sportplatz 22<br />
                07552 Gera<br />
                Deutschland</p>
              <p>
                <a href="mailto:info@dikitalsolutions.com">info@dikitalsolutions.com</a><br />
                <a href="https://dikitalsolutions.com" target="_blank" rel="noopener noreferrer">
                  dikitalsolutions.com
                </a>
              </p>
              <p>Geschäftsführer: Ronny Elsner<br />
                Register: GnR Jena, GnR 500103</p>
            </footer>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupForm; 