import React, { useState, useEffect } from 'react';
import Calendar from './components/Calendar';
import SignupForm from './components/SignupForm';
import './App.css';

// Hilfsfunktion außerhalb der Komponente
const canOpenDoor = (doorNumber) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  
  return currentMonth === 11 &&
         currentYear === 2024 &&
         currentDay >= doorNumber;
};

function App() {
  const [openDoors, setOpenDoors] = useState(() => {
    const saved = localStorage.getItem('openDoors');
    const savedDoors = saved ? JSON.parse(saved) : {};
    
    // Beim Laden prüfen und ungültige Türen schließen
    const validDoors = {};
    for (let doorNumber = 1; doorNumber <= 24; doorNumber++) {
      if (savedDoors[doorNumber] && canOpenDoor(doorNumber)) {
        validDoors[doorNumber] = true;
      }
    }
    return validDoors;
  });

  const [showSignup, setShowSignup] = useState(false);

  useEffect(() => {
    console.log('showSignup state in App.js:', showSignup);
  }, [showSignup]);

  useEffect(() => {
    localStorage.setItem('openDoors', JSON.stringify(openDoors));
  }, [openDoors]);

  const handleCloseSignup = () => {
    console.log('Closing signup form...');
    setShowSignup(false);
  };

  return (
    <div className="app">
      <h1 className="header">KI-Adventskalender</h1>
      <Calendar 
        openDoors={openDoors} 
        setOpenDoors={setOpenDoors} 
        setShowSignup={setShowSignup}
        canOpenDoor={canOpenDoor}
      />
      {showSignup && (
        <SignupForm onClose={handleCloseSignup} />
      )}
    </div>
  );
}

export default App; 